
<template>
  <div>
    <v-snackbar v-model="showSnackBar" color="black">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: white">
            {{ msg }}
          </span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#283e51"
      spinner="spinner"
    />
    <v-layout class="mainfont" wrap justify-center pt-8>
      <v-flex xs11 lg8 pl-3 pt-4>
        <v-layout wrap justify-center pt-5 style="background-color: white">
          <v-flex
            style="background-color: white"
            xs4
            sm1
            xs12
            class="text-sm-left text-xs-center"
          >
            <v-btn class="mx-2" small fab dark @click="$refs.calendar.prev()">
              <v-icon center>mdi-arrow-left</v-icon>
            </v-btn>
          </v-flex>
          <v-flex
            xs4
            sm2
            pt-2
            text-center
            style="font-size: 17px; font-weight: bold"
          >
            {{ formattedSelectedMonth }}
          </v-flex>

          <v-flex sm1 xs4 class="text-sm-right text-xs-center">
            <v-btn class="mx-2" small fab dark @click="$refs.calendar.next()">
              <v-icon center>mdi-arrow-right</v-icon>
            </v-btn>
          </v-flex>

          <v-flex xs12 class="mt-6">
            <v-sheet height="700">
              <v-calendar
                class="selectedDates"
                ref="calendar"
                v-model="selectedDates"
                :type="type"
                :end="end"
                color="primary"
              />
            </v-sheet>
          </v-flex>
        </v-layout>
      </v-flex>

      <v-flex pa-4 align-self-center xs11 lg4>
        <v-layout pl-3 pr-3 wrap justify-center style="background-color: white">
          <v-flex
            pt-3
            xs12
            text-left
            style="font-size: 25px; font-weight: bold"
          >
            <span>IB Booking</span>
          </v-flex>
          <v-flex
            pt-3
            xs12
            text-left
            style="font-size: 19px; font-weight: bold"
          >
            <span>{{ $route.query.accName }}</span
            >&nbsp;({{ range }})
          </v-flex>

          <v-flex xs12 text-left pt-1>
            <span style="color: grey">Selected Date </span>
          </v-flex>
          <v-flex xs12 pt-1>
            <span style="font-size: 26px; font-weight: bolder">{{
              formattedSelectedDate
            }}</span>
          </v-flex>

          <v-flex xs12 text-left pt-1 pb-1>
            <span style="color: grey">Availability </span>
          </v-flex>
          <v-flex text-center pa-2 xs12 style="background-color: #283e51">
            <span style="color: white"
              >{{ availableRooms }} Rooms Available</span
            >
          </v-flex>
          <v-flex xs12 pt-1>
            <v-divider></v-divider>
          </v-flex>
          <v-flex xs12 pt-3 pt-1>
            <v-text-field
              color="black"
              outlined
              background-color="white"
              dense
              label="No. of rooms Required"
              type="number"
              v-model="reqRooms"
              hide-details
            ></v-text-field>
          </v-flex>

          <v-flex xs12 pt-3 pt-1>
            <v-text-field
              color="black"
              outlined
              background-color="white"
              dense
              label="Guest Name"
              type="text"
              v-model="guestName"
              hide-details
            >
            </v-text-field>
          </v-flex>

          <v-flex xs12 pt-3 pt-1>
            <v-text-field
              color="black"
              outlined
              background-color="white"
              dense
              label="No. of Persons Accompanying "
              type="number"
              v-model="personAccompanyNo"
              hide-details
            >
            </v-text-field>
          </v-flex>
          <v-flex xs12 pt-3 pt-1>
            <v-text-field
              color="black"
              outlined
              background-color="white"
              dense
              label="Guest Phone No."
              type="number"
              :rules="PhoneRules"
              v-model="guestMobileNo"
              hide-details
            >
            </v-text-field>
          </v-flex>

          <v-flex xs12 pt-3 pt-1>
            <v-text-field
              color="black"
              outlined
              background-color="white"
              dense
              label="Referred By"
              type="text"
              v-model="referredBy"
              hide-details
            >
            </v-text-field>
          </v-flex>

          <v-flex xs12 pt-3 pt-1>
            <v-text-field
              color="black"
              outlined
              background-color="white"
              dense
              label="Reference Person Phone "
              type="number"
              :rules="PhoneRules"
              v-model="referencePersonPh"
              hide-details
            >
            </v-text-field>
          </v-flex>
          <v-flex xs12 pt-3 pt-1>
            <v-text-field
              color="black"
              outlined
              background-color="white"
              dense
              label="E-mail Address "
              :rules="emailRules"
              type="text"
              v-model="guestEmailId"
              hide-details
            >
            </v-text-field>
          </v-flex>

          <v-flex xs12 pt-3 pt-1>
            <v-autocomplete
              outlined
              dense
              label="Food Preference"
              color="black"
              :items="itemArray2"
              v-model="mealsType"
              item-text="name"
              item-value="name"
              item-color="#FF1313"
              hide-details
              clearable
              class="custom-autocomplete mainfont"
            >
              <template v-slot:append>
                <v-icon color="black">mdi-chevron-down</v-icon>
              </template>
            </v-autocomplete>
          </v-flex>

          <v-flex xs12 pt-3 pt-1>
            <v-text-field
              color="black"
              outlined
              background-color="white"
              dense
              label="No. of Vehicles"
              type="number"
              v-model="noOfVehicles"
              hide-details
            >
            </v-text-field>
          </v-flex>

          <v-flex xs12 pt-3 pt-1>
            <!-- <v-text-field color="black" outlined background-color="white" dense label="Vehicle Number"
                            type="text" v-model="vehicleNumbers" hide-details>
                        </v-text-field> -->

            <v-combobox
              label="Vehicle Number"
              v-model="vehicleNumbers"
              outlined
              :items="keyArray"
              multiple
              dense
              small-chips
              hint="Note: Type number and press Enter key"
            >
              <template v-slot:selection="{ attrs, item, parent, selected }">
                <span class="pa-1">
                  <v-chip v-bind="attrs" :input-value="selected" small>
                    <span
                      class="pr-2"
                      style="font-family: opensansregular; font-size: 13px"
                    >
                      {{ item }} </span
                    >&nbsp;
                    <v-icon small @click="parent.selectItem(item)">
                      mdi-close-circle
                    </v-icon>
                  </v-chip>
                </span>
              </template>
              <template v-slot:append>
                <span></span>
              </template>
            </v-combobox>
          </v-flex>

          <v-flex xs12 pt-1>
            <v-text-field
              color="black"
              outlined
              background-color="white"
              label="Any Other Details"
              type="text"
              v-model="anyOtherDetails"
              hide-details
            >
            </v-text-field>
          </v-flex>

          <v-flex xs12 pt-3 pb-4>
            <v-btn @click="add()" block color="#283e51">
              <span style="color: white">SUBMIT</span>
              <v-icon color="white">mdi-arrow-right</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
  
<script>
import moment from "moment";
import axios from "axios";

export default {
  data() {
    return {
      availableRooms: null,
      selectedDates: new Date(),
      date: new Date(),
      range: null,
      type: "month",
      end: "2019-01-06",
      appLoading: false,
      showSnackBar: false,
      msg: null,
      minDate: new Date().toISOString().substr(0, 10),
      disabledDays: [new Date(2023, 9, 9)],
      keyArray: [],

      availabledate: {},
      selectedSeats: 20,
      itemArray2: ["Vegeterian", "Non-vegetarian"],
      accid: null,
      guestCount: null,
      guestName: null,
      guestEmailId: null,
      guestMobileNo: null,
      selectedDate: null,
      referredBy: null,
      referencePersonPh: null,
      mealsType: null,
      noOfVehicles: null,
      vehicleNumbers: null,
      anyOtherDetails: null,
      reqRooms: null,
      accomodationName: null,
      personAccompanyNo: null,
    };
  },
  mounted() {
    this.checkAvailability();
  },
  computed: {
    PhoneRules() {
      return [
        (v) => !!v || "Phone number is required",
        (v) => /^[0-9]{10}$/.test(v) || "Phone number must be 10 digits",
      ];
    },
    emailRules() {
      return [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ];
    },

    computedMaxDueDate() {
      console.log("cscsdsdcdsds");
      const today = moment();
      const lastDayOfMonth = moment(today).endOf("month");
      return lastDayOfMonth.format("YYYY-MM-DD");
    },
    formattedSelectedMonth() {
      return moment(this.selectedDates).format("MMMM YYYY");
    },
    formattedSelectedDate() {
      return moment(this.selectedDates).format("MMMM D, YYYY");
    },
  },
  watch: {
    selectedDates() {
      this.checkAvailability();
    },
    reqRooms() {
      if (this.reqRooms > this.availableRooms) {
        this.msg = `Only ${this.availableRooms} Rooms Available`;
        this.showSnackBar = true;
      } else {
        this.showSnackBar = false;
      }
    },
  },
  methods: {
    formatTime(time) {
      return moment(time).format("HH:mm");
    },
    formatDate(item) {
      var dt = new Date(item);
      var year = dt.getFullYear();
      dt = dt.toString();
      var strTime = dt.slice(4, 10) + " " + year;
      return strTime;
    },
    add() {
      const requestData = {
        selectedDate: this.selectedDates,
        accid: this.$route.query.id,
        guestCount: this.guestCount,
        guestName: this.guestName,
        guestEmailId: this.guestEmailId,
        guestMobileNo: this.guestMobileNo,
        referredBy: this.referredBy,
        referencePersonPh: this.referencePersonPh,
        mealsType: this.mealsType,
        noOfVehicles: this.noOfVehicles,
        vehicleNumbers: this.vehicleNumbers,
        anyOtherDetails: this.anyOtherDetails,
        reqRooms: this.reqRooms,
        personAccompanyNo: this.personAccompanyNo,
      };

      axios({
        method: "POST",
        url: "/add/booking",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: requestData,
      })
        .then((response) => {
          if (response.data.status == true) {
            location.reload();
            this.msg = "Booked Successfully";
            this.showSnackBar = true;
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.error(err);
        });
    },
    checkAvailability() {
      axios({
        method: "POST",
        url: "/check/availability",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          date: this.selectedDates,
          accid: this.$route.query.id,
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            this.availableRooms = response.data.availableRooms;
            this.range = response.data.accomodationDetails.rangeid.officeName;

            this.accomodationName = response.data.accomodationName;
            // this.msg = response.data.msg;
            // this.showSnackBar = true;
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.error(err);
        });
    },
  },
};
</script>
  
<style scoped>
.selectedDates {
  background-color: green;
  color: white;
  text-align: center;
  padding: 10px;
  border-radius: 5px;
}

.rounded {
  border-radius: 25px;
}

.selectedDates {
  background-color: green;
  color: white;
  text-align: center;
  padding: 10px;
  border-radius: 5px;
}
</style>
  